import {Alert, Button, Card, Collapse, Grid, Snackbar, Stack, Typography} from '@mui/material'
import {Controller, SubmitHandler, UseFormReturn} from 'react-hook-form'
import React, {useContext, useEffect, useState} from 'react'
import {ChildDataContext, Feeding, Item, ItemType, Pumping, Meal} from '../contexts/ChildDataContext'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandMore from './util/ExpandMore'
import {DatePicker, TimePicker} from '@mui/x-date-pickers'
import {MEDIA_QUERY_DESKTOP} from '../util/Constants'

interface ItemFormProps {
  title: string
  itemToEdit?: Feeding | Pumping | Meal
  isEmpty: (item: Partial<Feeding | Pumping | Meal>) => boolean
  form: UseFormReturn
  itemType: ItemType
}

type FormData = {
  date?: Date
  time?: Date
} & Item

const ItemForm = (props: React.PropsWithChildren<ItemFormProps>) => {
  const context = useContext(ChildDataContext)
  const itemToEdit = props.itemToEdit
  const {handleSubmit, formState: {isValid}, reset, setValue} = props.form
  const editMode = !!itemToEdit
  const [success, setSuccess] = useState(false)
  const [warning, setWarning] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const handleClose = () => {
    setSuccess(false)
    setWarning(false)
  }
  const handleEditCancel = () => {
    if (itemToEdit) {
      context.onClearEditItem(itemToEdit)
      setExpanded(false)
    }
    reset()
  }
  const handleExpandClick = () => {
    setExpanded((prevState) => !prevState)
    handleEditCancel()
  }
  const onSubmit: SubmitHandler<Partial<FormData>> = data => {
    if (!props.isEmpty(data)) {
      if (editMode && itemToEdit) {
        const editedItem = {...itemToEdit, ...data}
        if (data.date && data.time) {
          editedItem.timestamp = new Date(data.date.getFullYear(), data.date.getMonth(), data.date.getDate(),
            data.time.getHours(), data.time.getMinutes())
        }
        context.onEditItem(editedItem)
      } else {
        context.onAddItem({...data, type: props.itemType})
      }
      setSuccess(true)
      setExpanded(false)
      reset()
    } else {
      setWarning(true)
    }
  }

  useEffect(() => {
    console.log('ItemForm - useEffect')
    if (itemToEdit) {
      if ('left' in itemToEdit) setValue('left', itemToEdit.left)
      if ('right' in itemToEdit) setValue('right', itemToEdit.right)
      if ('bottle' in itemToEdit) {
        setValue('bottle', itemToEdit.bottle)
        setValue('bottleType', itemToEdit.bottleType)
      }
      if ('name' in itemToEdit) setValue('name', itemToEdit.name)
      if ('amount' in itemToEdit) setValue('amount', itemToEdit.amount)
      setValue('date', itemToEdit.timestamp)
      setValue('time', itemToEdit.timestamp)
      setExpanded(true)
    }
  }, [itemToEdit, setValue, expanded])

  return <Card variant="outlined" sx={{padding: 1, marginY: 1}}>
    <Snackbar autoHideDuration={3500}
              anchorOrigin={{horizontal: 'center', vertical: 'bottom'}}
              sx={{mb: 4}}
              open={success}
              onClose={handleClose}>
      <Alert onClose={handleClose} severity="success">
        {props.title} saved
      </Alert>
    </Snackbar>
    <Snackbar autoHideDuration={3500}
              anchorOrigin={{horizontal: 'center', vertical: 'bottom'}}
              sx={{mb: 4}}
              open={warning}
              onClose={handleClose}>
      <Alert onClose={handleClose} severity="warning">
        Empty item &#x2015; not saving
      </Alert>
    </Snackbar>
    <Typography variant="body1" onClick={handleExpandClick}>{props.title}
      <ExpandMore
        expand={expanded}
        aria-expanded={expanded}
        aria-label="show more">
        <ExpandMoreIcon/>
      </ExpandMore>
    </Typography>
    <Collapse in={expanded} timeout="auto" unmountOnExit>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container alignItems="center" p={1}>
          {props.children}
          {editMode ?
            <>
              <Grid item xs={6}>
                <Controller name="date"
                            control={props.form.control}
                            defaultValue={new Date()}
                            render={({field: {onChange, value}}) =>
                              <DatePicker label="Date"
                                          desktopModeMediaQuery={MEDIA_QUERY_DESKTOP}
                                          onChange={onChange}
                                          value={value}/>}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller name="time"
                            control={props.form.control}
                            defaultValue={new Date()}
                            render={({field: {onChange, value}}) =>
                              <TimePicker label="Time"
                                          desktopModeMediaQuery={MEDIA_QUERY_DESKTOP}
                                          onChange={onChange}
                                          value={value}/>}
                />
              </Grid>
            </> : null
          }
          <Grid item xs={12}>
            <Stack direction="row" spacing={1}>
              <Button type="submit" variant="contained" color="primary" disabled={!isValid} fullWidth>
                {editMode ? 'Update' : 'Save'}
              </Button>
              {editMode &&
                <Button variant="contained" color="inherit" onClick={handleEditCancel} fullWidth>Cancel</Button>}
            </Stack>
          </Grid>
        </Grid>
      </form>
    </Collapse>
  </Card>
}

export default ItemForm

import {ChildDataContext, Pumping} from '../contexts/ChildDataContext'
import {useContext} from 'react'
import {Chip, Grid, IconButton, Stack, Typography} from '@mui/material'
import {formatRelative} from 'date-fns'
import {Delete, Edit} from '@mui/icons-material'

const PumpingHistoryItem = ({pumping}: {pumping: Pumping}) => {
  const feedingContext = useContext(ChildDataContext)

  return <Grid container columnSpacing={1} my={1}>
    <Grid item xs={8}>
      <Typography variant="subtitle2">
        Pumping &#x2015; {formatRelative(pumping.timestamp, new Date())}
      </Typography>
    </Grid>
    <Grid item xs={4} textAlign="end">
      <IconButton onClick={() => feedingContext.onSetPumpingToEdit(pumping)}><Edit/></IconButton>
      <IconButton onClick={() => feedingContext.onDeleteItem(pumping.id)}><Delete/></IconButton>
    </Grid>
    <Grid item xs={12}>
      <Stack direction="row" spacing={1}>
        {pumping.left ? <Chip color="warning" label={`Left: ${pumping.left}mL`}/> : ''}
        {pumping.right ? <Chip color="warning" label={`Right: ${pumping.right}mL`}/> : ''}
      </Stack>
    </Grid>
  </Grid>
}

export default PumpingHistoryItem

const STORAGE_KEY_THEME = 'currentTheme'
type Theme = 'light' | 'dark'

export function toggleSavedTheme() {
  console.log('Toggling saved theme')
  if (getTheme() === 'light') {
    localStorage.setItem(STORAGE_KEY_THEME, 'dark')
  } else {
    localStorage.setItem(STORAGE_KEY_THEME, 'light')
  }
}

export function getTheme() {
  const storedTheme = localStorage.getItem(STORAGE_KEY_THEME)
  if (!storedTheme) {
    return 'light'
  } else {
    return storedTheme as Theme
  }
}

export function isDarkTheme() { return getTheme() === 'dark'}

import {Typography} from '@mui/material'
import {ChildDataContext} from '../contexts/ChildDataContext'
import FeedingForm from '../components/FeedingForm'
import PumpingForm from '../components/PumpingForm'
import ItemHistory from '../components/ItemHistory'
import {useContext, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import MealForm from '../components/MealForm'

const TrackerPage = () => {
  const childDataContext = useContext(ChildDataContext)
  const {childId} = useParams()
  const [childName, setChildName] = useState<string>('')
  useEffect(() => {
    if (childDataContext.children.length) {
      setChildName(childDataContext.children.find(child => child.id === childId)?.name || 'Unknown child')
    }
  }, [childId, childDataContext.children])
  console.log(`Child id: ${childId}, child name: ${childName}`)

  return <>
    <Typography variant="h1" fontSize="2em">{childName}&nbsp;</Typography>
    <FeedingForm/>
    <PumpingForm/>
    <MealForm/>
    <ItemHistory childId={childId}/>
  </>
}

export default TrackerPage

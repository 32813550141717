import {useForm} from 'react-hook-form'
import {useContext} from 'react'
import {ChildDataContext, ItemType, Pumping} from '../contexts/ChildDataContext'
import FormSlider from './FormSlider'
import ItemForm from './ItemForm'

const PumpingForm = () => {
  const context = useContext(ChildDataContext)
  const form = useForm<Partial<Pumping>>({
    defaultValues: {left: 0, right: 0},
  })
  const isEmpty = (pumping: Partial<Pumping>) => !pumping.left && !pumping.right

  return <ItemForm title="Pumping" isEmpty={isEmpty} form={form} itemType={ItemType.Pumping}
                   itemToEdit={context.pumpingToEdit}>
    <FormSlider label="Left:" control={form.control} name="left" labelType="milliliters" max={200}/>
    <FormSlider label="Right:" control={form.control} name="right" labelType="milliliters" max={200}/>
  </ItemForm>
}

export default PumpingForm

import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material'
import {LoadingButton} from '@mui/lab'
import {PropsWithChildren} from 'react'

type ConfirmationModalProps = {
  show: boolean
  onCancel: () => void
  onConfirm: () => void
  message: string
  title?: string
  action?: string
  actionColor?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | undefined
}

const ConfirmationModal = ({show, onCancel, onConfirm, title, message, action, actionColor}:
                             PropsWithChildren<ConfirmationModalProps>) => {

  return <Dialog open={show} disableEscapeKeyDown={true}>
    <DialogTitle>{title || 'Confirmation'}</DialogTitle>
    <DialogContent>
      <DialogContentText>{message}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel}>Cancel</Button>
      <LoadingButton color={actionColor} onClick={onConfirm}>{action || 'Confirm'}</LoadingButton>
    </DialogActions>
  </Dialog>
}

export default ConfirmationModal

import React, {useContext, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {
  AppBar,
  Box,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
  Toolbar,
  Typography
} from '@mui/material'
import {ChildCare, DarkMode, Face, InfoOutlined, Login, Logout, Menu} from '@mui/icons-material'
import {UserContext} from '../contexts/UserContext'
import {BREAKPOINT_APP_DRAWER, WIDTH_PX_APP_DRAWER} from '../util/Constants'
import {ChildDataContext} from '../contexts/ChildDataContext'
import {isDarkTheme, toggleSavedTheme} from '../util/Storage'

const {VITE_APP_VERSION} = import.meta.env

type DrawerAction = 'children' | 'logout' | 'about' | 'login'
const AppDrawer = ({toggleTheme}: { toggleTheme: (theme: 'light' | 'dark') => void }) => {
  const userContext = useContext(UserContext)
  const childDataContext = useContext(ChildDataContext)
  const [mobileOpen, setMobileOpen] = useState(false)
  const [themeChecked, setThemeChecked] = useState(isDarkTheme())
  const navigate = useNavigate()
  const handleDrawerToggle = () => {
    setMobileOpen(prevState => !prevState)
  }
  const handleThemeToggle = () => {
    setThemeChecked(isDark => {
      toggleSavedTheme()
      toggleTheme(isDark ? 'light' : 'dark')
      return !isDark
    })
  }
  const onDrawerItemClick = (item: DrawerAction) => {
    switch (item) {
      case 'children':
        navigate('/')
        break
      case 'logout':
        userContext.signOut()
          .then(() => navigate('/login'))
        break
      case 'login':
        navigate('/login')
        break
      case 'about':
        navigate('/about')
        break
    }
    setMobileOpen(false)
  }
  const onChildClick = (childId: string) => {
    navigate(`/child/${childId}`)
    setMobileOpen(false)
  }

  const drawer = (
    <List>
      <Box sx={{display: userContext.isAuthenticated ? 'initial' : 'none'}}>
        <ListItem disablePadding>
          <ListItemButton onClick={() => onDrawerItemClick('children')}>
            <ListItemIcon><ChildCare/></ListItemIcon>
            <ListItemText primary="Children"/>
          </ListItemButton>
        </ListItem>
        <List component="div" disablePadding>
          {childDataContext.children.map(child =>
            <ListItem disablePadding key={child.id}>
              <ListItemButton sx={{pl: 4}} onClick={() => onChildClick(child.id)}>
                <ListItemIcon><Face/></ListItemIcon>
                <ListItemText primary={child.name}/>
              </ListItemButton>
            </ListItem>
          )}
          <Divider/>
        </List>
      </Box>
      <ListItem disablePadding>
        <ListItemButton onClick={() => onDrawerItemClick('about')}>
          <ListItemIcon>
            <InfoOutlined/>
          </ListItemIcon>
          <ListItemText primary="About"/>
        </ListItemButton>
      </ListItem>
      <Box>
        <Divider/>
        <ListItem disablePadding sx={{display: userContext.isAuthenticated ? 'initial' : 'none'}}>
          <ListItemButton onClick={() => onDrawerItemClick('logout')}>
            <ListItemIcon>
              <Logout/>
            </ListItemIcon>
            <ListItemText primary="Logout"/>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding sx={{display: !userContext.isAuthenticated ? 'initial' : 'none'}}>
          <ListItemButton onClick={() => onDrawerItemClick('login')}>
            <ListItemIcon>
              <Login/>
            </ListItemIcon>
            <ListItemText primary="Login"/>
          </ListItemButton>
        </ListItem>
      </Box>
      <Divider/>
      <ListItem>
        <ListItemIcon><DarkMode/></ListItemIcon>
        <ListItemText>Theme</ListItemText>
        <ListItemSecondaryAction>
          <Switch checked={themeChecked} onChange={() => handleThemeToggle()}/>
        </ListItemSecondaryAction>
      </ListItem>
      <Divider/>
      <ListItem disablePadding>
        <ListItemText sx={{textAlign: 'center'}}>v{VITE_APP_VERSION}</ListItemText>
      </ListItem>
    </List>
  )

  return <Box sx={{display: 'flex'}}>
    <CssBaseline/>
    <AppBar
      position="fixed"
      sx={{
        width: {[BREAKPOINT_APP_DRAWER]: `calc(100% - ${WIDTH_PX_APP_DRAWER}px)`},
        ml: {[BREAKPOINT_APP_DRAWER]: `${WIDTH_PX_APP_DRAWER}px`},
      }}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{mr: 2, display: {[BREAKPOINT_APP_DRAWER]: 'none'}}}>
          <Menu/>
        </IconButton>
        <Typography variant="h6" noWrap component="div">
          Baby Tracker
        </Typography>
      </Toolbar>
    </AppBar>
    <Drawer
      container={window.document.body}
      variant="temporary"
      open={mobileOpen}
      onClose={handleDrawerToggle}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{
        display: {xs: 'block', [BREAKPOINT_APP_DRAWER]: 'none'},
        '& .MuiDrawer-paper': {boxSizing: 'border-box', width: WIDTH_PX_APP_DRAWER},
      }}>
      {drawer}
    </Drawer>
    <Drawer
      variant="permanent"
      sx={{
        display: {xs: 'none', [BREAKPOINT_APP_DRAWER]: 'block'},
        '& .MuiDrawer-paper': {boxSizing: 'border-box', width: WIDTH_PX_APP_DRAWER},
      }}
      open>
      {drawer}
    </Drawer>
  </Box>
}

export default AppDrawer

import {Typography} from '@mui/material'
import {browserLocalPersistence, GoogleAuthProvider, onAuthStateChanged, setPersistence, User} from 'firebase/auth'
import {auth} from 'firebaseui'
import React, {useContext, useEffect, useState} from 'react'
import 'firebaseui/dist/firebaseui.css'
import {UserContext} from '../contexts/UserContext'
import {useLocation, useNavigate} from 'react-router-dom'
import {firebaseAuth} from '../FireBaseConfig'
import LoadingSpinner from '../components/util/LoadingSpinner'

const LoginPage = () => {
  const ELEMENT_ID = 'firebaseui-container'
  const userContext = useContext(UserContext)
  const [loading, setLoading] = useState(true)
  console.log(`LoadingPage loading: ${loading}`)
  const navigate = useNavigate()
  const redirectPath = new URLSearchParams(useLocation().search).get('redirect') || '/'
  console.log(`LoginPage redirect path: ${redirectPath}`)

  useEffect(() => {
    // Configure FirebaseUI.
    const uiConfig: auth.Config = {
      siteName: 'Baby Tracker',
      signInFlow: 'popup',
      signInOptions: [
        GoogleAuthProvider.PROVIDER_ID
      ],
      callbacks: {
        signInSuccessWithAuthResult: () => false
      }
    }

    // Get or create a firebaseUI instance.
    let firebaseUiWidget: auth.AuthUI
    if (auth.AuthUI.getInstance()) {
      console.log('Login Page: static widget set')
      firebaseUiWidget = auth.AuthUI.getInstance()!!
      firebaseUiWidget.reset()
    } else {
      console.log('Login Page: widget created')
      firebaseUiWidget = new auth.AuthUI(firebaseAuth)
    }

    // We track the auth state to reset firebaseUi if the user signs out.
    console.log('LoginPage.subscribe')
    const unregisterAuthObserver = onAuthStateChanged(firebaseAuth, (user) => {
      if (loading) {
        console.log('LoginPage: set loading false')
        setLoading(false)
      }
      if (user) {
        // Already logged in
        console.log('Login Page: user already logged in', user)
        userContext.setUser(user as User)
        console.log('Navigating due to user already logged in')
        navigate(redirectPath, {replace: true})
      } else {
        // Render the firebaseUi Widget after setting persistence
        setPersistence(firebaseAuth, browserLocalPersistence)
          .then(() => firebaseUiWidget.start(`#${ELEMENT_ID}`, uiConfig))
        console.log('Login Page: no user')
      }
    })

    return () => {
      console.log('LoginPage.unsubscribe')
      unregisterAuthObserver()
      firebaseUiWidget.reset()
    }
  }, [navigate, userContext, loading, redirectPath])

  return <>
    <Typography variant="h1" fontSize="2em" mb={5}>Login</Typography>
    {/*<Stack alignItems="center">*/}
    {/*  <button onClick={() => signInAnonymously(firebaseAuth)}*/}
    {/*          className="firebaseui-idp-button mdl-button mdl-js-button mdl-button--raised firebaseui-idp-anonymous firebaseui-id-idp-button"*/}
    {/*          data-provider-id="anonymous" style={{backgroundColor: '#f4b400'}}><span*/}
    {/*    className="firebaseui-idp-icon-wrapper"><img className="firebaseui-idp-icon" alt=""*/}
    {/*                                                 src={anonymous}/></span><span*/}
    {/*    className="firebaseui-idp-text">Continue as guest</span></button>*/}
    {/*</Stack>*/}
    <div id={ELEMENT_ID}/>
    <LoadingSpinner show={loading}/>
  </>
}

export default LoginPage

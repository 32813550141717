import {PropsWithChildren, useContext} from 'react'
import {UserContext} from '../contexts/UserContext'
import {Navigate, useLocation} from 'react-router-dom'

const RequireAuth = ({children}: PropsWithChildren) => {
  const isAuthenticated = useContext(UserContext).isAuthenticated
  const location = useLocation()
  console.log('Location', location)
  return isAuthenticated ? <>{children}</> : <Navigate to={`/login?redirect=${location.pathname}`} replace/>
}

export default RequireAuth

import {ChildDataContext, Meal} from '../contexts/ChildDataContext'
import {useContext} from 'react'
import {Chip, Grid, IconButton, Typography} from '@mui/material'
import {formatRelative} from 'date-fns'
import {Delete, Edit} from '@mui/icons-material'
import {lightBlue} from '@mui/material/colors'

type MealHistoryItemItemProps = {
  meal: Meal
  onDeleteItem: (id: string) => void
}

const MealHistoryItem = ({meal, onDeleteItem}: MealHistoryItemItemProps) => {
  const childDataContext = useContext(ChildDataContext)

  return <Grid container columnSpacing={1} my={1}>
    <Grid item xs={8}>
      <Typography variant="subtitle2">
        Meal &#x2015; {formatRelative(meal.timestamp, new Date())}
      </Typography>
    </Grid>
    <Grid item xs={4} textAlign="end">
      <IconButton onClick={() => childDataContext.onSetMealToEdit(meal)}><Edit/></IconButton>
      <IconButton onClick={() => onDeleteItem(meal.id)}><Delete/></IconButton>
    </Grid>
    <Grid item xs={12}>
      <Chip color="secondary"
            label={`${meal.name}: ${meal.amount}oz`}
            sx={{backgroundColor: lightBlue[300]}}/>
    </Grid>
  </Grid>
}

export default MealHistoryItem

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import {useContext, useState} from 'react'
import {Child, ChildDataContext} from '../contexts/ChildDataContext'
import {Controller, SubmitHandler, useForm} from 'react-hook-form'
import {useNavigate} from 'react-router-dom'
import {Delete, Share} from '@mui/icons-material'
import {REGEX_EMAIL} from '../util/Constants'
import {LoadingButton} from '@mui/lab'

const ChildPage = () => {
  const childDataContext = useContext(ChildDataContext)
  const shareForm = useForm<{ email: string }>({
    defaultValues: {email: ''}
  })
  const [modelOpen, setModelOpen] = useState(false)
  const [shareChild, setShareChild] = useState<Child>()
  const [shareChildLoading, setShareChildLoading] = useState(false)
  const navigate = useNavigate()
  const {handleSubmit, control, formState: {isValid, isDirty}, reset} = useForm<Partial<Child>>()
  const onSubmit: SubmitHandler<Partial<Child>> = data => {
    console.log('Child to add', data)
    childDataContext.addChild(data)
      .then(child => console.log(child))
    reset()
  }
  const onChildClick = (child: Child) => {
    navigate(`/child/${child.id}`)
  }
  const onDeleteChild = (child: Child) => {
    childDataContext.deleteChild(child.id)
      .then(() => console.log('Child deleted:', child))
  }
  const handleModalOpen = () => {
    shareForm.reset()
    setModelOpen(true)
  }
  const onCloseModal = () => setModelOpen(false)
  const onModalSubmit = () => {
    console.log(`Sharing child with email: ${shareForm.getValues('email')}`, shareChild)
    setShareChildLoading(true)
    if (shareChild) {
      childDataContext.shareChild(shareChild, shareForm.getValues('email'))
        .then(result => {
          console.log('Share child result:', result)
          onCloseModal()
        })
        .catch(error => {
          console.error('Error sharing child', error)
          shareForm.setError('email', {message: 'There was a problem sharing, please try again'})
        })
        .finally(() => {
          setShareChildLoading(false)
        })
    } else {
      // TODO: some error here
    }
  }
  const onShareChild = (child: Child) => {
    setShareChild(child)
    handleModalOpen()
  }

  return <>
    <Typography variant="h1" fontSize="2em">Children</Typography>
    {/*TODO: most this modal to it's own component */}
    <Dialog open={modelOpen} onClose={onCloseModal}>
      <DialogTitle>Share access to: {shareChild?.name}</DialogTitle>
      <form onSubmit={shareForm.handleSubmit(onModalSubmit)}>
        <DialogContent>
          <DialogContentText>
            Enter the email of the person you would like to grant access to.
            They must have logged into the app before.
          </DialogContentText>
          <Controller name="email"
                      control={shareForm.control}
                      rules={{required: true, pattern: REGEX_EMAIL}}
                      render={({field: {onChange, value}, formState: {errors: {email: emailError}}}) =>
                        <TextField
                          value={value}
                          error={!!emailError}
                          helperText={emailError?.message}
                          onChange={onChange}
                          autoFocus
                          margin="dense"
                          id="email"
                          label="Email address"
                          type="email"
                          fullWidth
                          variant="standard"
                        />}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onCloseModal}>Cancel</Button>
          <LoadingButton loading={shareChildLoading} type="submit" disabled={!shareForm.formState.isValid}>
            Share
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
    <List>
      {childDataContext.children.map(child =>
        <ListItem disablePadding key={child.id} divider={true} secondaryAction={
          <>
            <Tooltip title="Share">
              <IconButton onClick={() => onShareChild(child)}><Share/></IconButton>
            </Tooltip>
            {/*<Tooltip title="Delete">*/}
            <IconButton onClick={() => onDeleteChild(child)} disabled><Delete/></IconButton>
            {/*</Tooltip>*/}
          </>
        }>
          <ListItemButton onClick={() => onChildClick(child)}>
            <Typography variant="subtitle1">{child.name}</Typography>
          </ListItemButton>
        </ListItem>
      )}
    </List>
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack my={2}>
        <Controller name="name"
                    control={control}
                    defaultValue=""
                    rules={{required: true, minLength: 2, maxLength: 40}}
                    render={({field: {onChange, value}}) =>
                      <TextField label="Child's Name"
                                 variant="outlined"
                                 value={value}
                                 onChange={onChange}
                      />}
        />
        <Button type="submit" variant="outlined" disabled={!isValid || !isDirty}>Add Child</Button>
      </Stack>
    </form>
  </>
}

export default ChildPage

import {BottleType, ChildDataContext, Feeding} from '../contexts/ChildDataContext'
import {useContext} from 'react'
import {Chip, Grid, IconButton, Stack, Typography} from '@mui/material'
import {formatRelative} from 'date-fns'
import {Delete, Edit} from '@mui/icons-material'
import {pink, green} from '@mui/material/colors'

type FeedingHistoryItemProps = {
  feeding: Feeding
  onDeleteItem: (id: string) => void
}

const FeedingHistoryItem = ({feeding, onDeleteItem}: FeedingHistoryItemProps) => {
  const feedingContext = useContext(ChildDataContext)
  let bottleType = feeding.bottleType ? BottleType.Formula : BottleType.BreastMilk
  const bottleColor = feeding.bottleType ? green[400] : pink[400]

  return <Grid container columnSpacing={1} my={1}>
    <Grid item xs={8}>
      <Typography variant="subtitle2">
        Feeding &#x2015; {formatRelative(feeding.timestamp, new Date())}
      </Typography>
    </Grid>
    <Grid item xs={4} textAlign="end">
      <IconButton onClick={() => feedingContext.onSetFeedingToEdit(feeding)}><Edit/></IconButton>
      <IconButton onClick={() => onDeleteItem(feeding.id)}><Delete/></IconButton>
    </Grid>
    <Grid item xs={12}>
      <Stack direction="row" spacing={1}>
        {feeding.left ? <Chip color="secondary" label={`Left: ${feeding.left} min`}/> : ''}
        {feeding.right ? <Chip color="secondary" label={`Right: ${feeding.right} min`}/> : ''}
        {feeding.bottle ? <Chip color="info" sx={{backgroundColor: bottleColor}}
                                label={`Bottle-${bottleType}: ${feeding.bottle}mL`}/> : ''}
      </Stack>
    </Grid>
  </Grid>
}

export default FeedingHistoryItem

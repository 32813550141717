import {createContext, PropsWithChildren, useState} from 'react'
import {User} from 'firebase/auth'
import {signOut} from 'firebase/auth'
import {firebaseAuth} from '../FireBaseConfig'

export const UserContext = createContext<UserContextType>({} as UserContextType)

type UserContextType = {
  user?: User,
  setUser: (user: User) => void,
  isAuthenticated: boolean,
  isAnonymous: boolean,
  signOut: () => Promise<void>,
}

export default function UserContextProvider(props: PropsWithChildren) {
  const [user, setUser] = useState<User | undefined>(undefined)
  const localSignOut = async () => {
    setUser(undefined)
    console.log('Signing Out')
    return signOut(firebaseAuth)
  }
  const contextValue = {
    user,
    setUser,
    isAuthenticated: !!user,
    isAnonymous: user?.isAnonymous || false,
    signOut: localSignOut
  }
  console.log('UserContext started:', user)
  console.log('UserContext current user:', firebaseAuth.currentUser)

  return <UserContext.Provider value={contextValue}>
    {props.children}
  </UserContext.Provider>
}

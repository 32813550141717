import {connectFirestoreEmulator, enableIndexedDbPersistence, getFirestore} from 'firebase/firestore'
import {initializeApp} from 'firebase/app'
import {connectFunctionsEmulator, getFunctions} from 'firebase/functions'
import {connectAuthEmulator, getAuth} from 'firebase/auth'

const {
  VITE_FIREBASE_API_KEY,
  VITE_FIREBASE_AUTH_DOMAIN,
  VITE_FIREBASE_PROJECT_ID,
  VITE_FIREBASE_APP_ID,
  VITE_USE_EMULATORS
} = import.meta.env
// Your web app's Firebase configuration
export const firebaseConfig = {
  apiKey: VITE_FIREBASE_API_KEY,
  authDomain: VITE_FIREBASE_AUTH_DOMAIN,
  projectId: VITE_FIREBASE_PROJECT_ID,
  appId: VITE_FIREBASE_APP_ID
} // Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig)
export const firestoreDb = getFirestore(firebaseApp)
export const firebaseFunctions = getFunctions(firebaseApp)
export const firebaseAuth = getAuth(firebaseApp)
if (VITE_USE_EMULATORS) {
  console.log('Connecting emulator')
  connectFirestoreEmulator(firestoreDb, window.location.hostname, 3001)
  connectAuthEmulator(firebaseAuth, `http://${window.location.hostname}:3004`, {disableWarnings: true})
  connectFunctionsEmulator(firebaseFunctions, window.location.hostname, 3002)
} else {
  enableIndexedDbPersistence(firestoreDb)
    .catch((err) => {
      if (err.code === 'failed-precondition') {
        console.error('FeedingContext unable to enable indexed db, failed precondition', err.message)
      } else if (err.code === 'unimplemented') {
        console.error('FeedingContext unable to enable indexed db, browser missing support for indexed db', err.message)
      }
    })
}

import {Box, CircularProgress} from '@mui/material'

export const LoadingSpinner = ({show}: { show: boolean }) => {

  return show ?
    <Box data-testid="loading-spinner"
         sx={{position: 'absolute', left: 0, top: 0, right: 0, bottom: 0, opacity: 0.5, backgroundColor: 'white'}}>
      <CircularProgress sx={{position: 'relative', top: 'calc(50% - 20px)', left: 'calc(50% - 20px)'}}/>
    </Box> : <></>
}

export default LoadingSpinner

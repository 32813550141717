import {Meal, ChildDataContext, ItemType} from '../contexts/ChildDataContext'
import ItemForm from './ItemForm'
import FormSlider from './FormSlider'
import {Controller, useForm} from 'react-hook-form'
import React, {useContext} from 'react'
import {TextField, Typography} from '@mui/material'

const MAX_OUNCES_MEAL = 8

const MealForm = () => {
  const context = useContext(ChildDataContext)
  const form = useForm<Partial<Meal>>({
    defaultValues: {name: '', amount: 0},
  })
  const isEmpty = (meal: Partial<Meal>) => !meal.name || !meal.amount

  return <ItemForm title="Meal" isEmpty={isEmpty} form={form} itemType={ItemType.Meal}
                   itemToEdit={context.mealToEdit}>
    <Controller name="name"
                defaultValue=""
                control={form.control}
                render={({field: {onChange, value}}) =>
                  <TextField value={value} onChange={onChange} label="Meal name" sx={{mb: 1}} fullWidth/>
                }
    />
    <FormSlider label="Amount:"
                control={form.control}
                name="amount"
                labelType="ounces"
                max={MAX_OUNCES_MEAL}
                step={0.5}/>

  </ItemForm>
}

export default MealForm

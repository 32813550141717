import {Feeding, ChildDataContext, ItemType} from '../contexts/ChildDataContext'
import ItemForm from './ItemForm'
import FormSlider from './FormSlider'
import {useForm} from 'react-hook-form'
import {useContext} from 'react'

const MAX_MINUTES_FEEDING = 60
const MAX_MILLIMETERS_FEEDING = 200

const FeedingForm = () => {
  const context = useContext(ChildDataContext)
  const form = useForm<Partial<Feeding>>({
    defaultValues: {left: 0, right: 0, bottle: 0},
  })
  const isEmpty = (feeding: Partial<Feeding>) => !feeding.bottle && !feeding.left && !feeding.right

  return <ItemForm title="Feeding" isEmpty={isEmpty} form={form} itemType={ItemType.Feeding}
                   itemToEdit={context.feedingToEdit}>
    <FormSlider label="Left:" control={form.control} name="left" labelType="minutes" max={MAX_MINUTES_FEEDING}/>
    <FormSlider label="Right:" control={form.control} name="right" labelType="minutes" max={MAX_MINUTES_FEEDING}/>
    <FormSlider label="Bottle:" control={form.control} name="bottle" labelType="milliliters"
                max={MAX_MILLIMETERS_FEEDING} step={5} showSwitch={true} switchName="bottleType"
                switchOffLabel="Breast Milk" switchOnLabel="Formula"/>
  </ItemForm>
}

export default FeedingForm

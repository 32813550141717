import {Card, List, ListItem, ListItemButton, ListItemText, Typography} from '@mui/material'
import {useContext, useEffect, useState} from 'react'
import {ChildDataContext, ItemType} from '../contexts/ChildDataContext'
import FeedingHistoryItem from './FeedingHistoryItem'
import PumpingHistoryItem from './PumpingHistoryItem'
import ConfirmationModal from './util/ConfirmationModal'
import MealHistoryItem from './MealHistoryItem'

const ItemHistory = ({childId}: { childId?: string }) => {
  const childDataContext = useContext(ChildDataContext)
  const [modelState, setModelState] = useState<{ open: boolean, id?: string }>({open: false})
  useEffect(() => {
    childDataContext.setCurrentChildId(childId)
  }, [childId, childDataContext])
  const onDeleteModalCancel = () => {
    setModelState({open: false, id: undefined})
  }
  const onDeleteModalConfirm = () => {
    childDataContext.onDeleteItem(modelState.id!)
    setModelState({open: false, id: undefined})
  }

  return <Card variant="outlined" sx={{padding: 1, marginY: 1}}>
    <ConfirmationModal title="Delete Item"
                       message="Are you sure you want to delete?"
                       action="Delete"
                       actionColor="error"
                       show={modelState.open}
                       onCancel={onDeleteModalCancel}
                       onConfirm={onDeleteModalConfirm}/>
    <Typography variant="body1">History</Typography>
    <List>
      {childDataContext.items.map(item => {
        if (item.type === ItemType.Feeding) {
          return <ListItem key={item.id} divider={true} disablePadding>
            <FeedingHistoryItem feeding={item} onDeleteItem={() => setModelState({open: true, id: item.id})}/>
          </ListItem>
        } else if (item.type === ItemType.Pumping) {
          return <ListItem key={item.id} divider={true} disablePadding>
            <PumpingHistoryItem pumping={item}/>
          </ListItem>
        } else if (item.type === ItemType.Meal) {
          return <ListItem key={item.id} divider={true} disablePadding>
            <MealHistoryItem meal={item} onDeleteItem={() => setModelState({open: true, id: item.id})}/>
          </ListItem>
        } else {
          return <div>UNKNOWN ITEM</div>
        }
      })
      }
      <ListItem disablePadding>
        <ListItemButton onClick={() => childDataContext.incrementItemCount()}>
          <ListItemText sx={{textAlign: 'center'}}>Load More History</ListItemText>
        </ListItemButton>
      </ListItem>
    </List>
  </Card>
}

export default ItemHistory

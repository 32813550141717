import React, {createContext, PropsWithChildren, useContext, useEffect, useState} from 'react'
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  limit,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  where,
} from 'firebase/firestore'
import {httpsCallable} from 'firebase/functions'
import {firebaseFunctions, firestoreDb} from '../FireBaseConfig'
import {UserContext} from './UserContext'

const {VITE_CHILD_LOAD_COUNT} = import.meta.env
const CHILD_LOAD_COUNT = parseInt(VITE_CHILD_LOAD_COUNT || '20')

export const ChildDataContext = createContext<ChildDataContextType>({} as ChildDataContextType)

export default function ChildDataContextProvider(props: PropsWithChildren) {
  console.log('ChildDataContext created')
  const userContext = useContext(UserContext)
  const [items, setItems] = useState<Item[]>([])
  const [children, setChildren] = useState<Child[]>([])
  const [itemHistoryCount, setItemHistoryCount] = useState(CHILD_LOAD_COUNT)
  const [childId, setChildId] = useState<string>()
  const [feedingToEdit, setFeedingToEdit] = useState<Feeding | undefined>()
  const [pumpingToEdit, setPumpingToEdit] = useState<Pumping | undefined>()
  const [mealToEdit, setMealToEdit] = useState<Meal | undefined>()
  useEffect(() => {
    if (childId) {
      const historyQuery = query(collection(firestoreDb, 'child', childId, 'history'),
        orderBy('timestamp', 'desc'), limit(itemHistoryCount))
      console.log('ChildDataContext: subscribe to item history')
      const unsubscribe = onSnapshot(historyQuery, snapshot => {
        const docs = snapshot.docs.map(doc => {
          const data = doc.data()
          if (doc.metadata.hasPendingWrites) {
            console.log('Event from a local update')
          }
          return {
            ...data,
            id: doc.id,
            timestamp: data?.timestamp?.toDate() || new Date()
          } as Item
        })
        console.log('Docs:', docs)
        setItems(docs)
      })

      return () => {
        console.log('ChildDataContext: unsubscribe from item history')
        unsubscribe()
      }
    }
  }, [childId, itemHistoryCount])

  useEffect(() => {
    const uid = userContext.user?.uid
    console.log(`ChildDataContext: uid = ${uid}`)
    if (uid) {
      const childrenQuery = query(collection(firestoreDb, 'child'),
        where(`roles.${uid}`, '==', 'editor'))
      console.log('ChildDataContext: subscribe to children')
      const unsubscribe = onSnapshot(childrenQuery, snapshot => {
        const children = snapshot.docs
          .map(doc => doc.data() as Child)
          .sort((a, b) => a.name.localeCompare(b.name))
        console.log('Children:', children)
        setChildren(children)
      }, error => {
        console.error('Error fetching children', error)
      })

      return () => {
        console.log('ChildDataContext: unsubscribe from children')
        unsubscribe()
      }
    }
  }, [userContext.user])

  const onAddItem = (item: Partial<Item>) => {
    const newItem = {
      ...item,
      timestamp: serverTimestamp(),
    }
    console.log('Item to add', newItem)
    if (childId) {
      addDoc(collection(firestoreDb, 'child', childId, 'history'), newItem)
    } else {
      // TODO: do something here
    }
  }
  const onClearEditItem = (item?: Item) => {
    if (item?.type === ItemType.Feeding) {
      setFeedingToEdit(undefined)
    } else if (item?.type === ItemType.Pumping) {
      setPumpingToEdit(undefined)
    } else if (item?.type === ItemType.Meal) {
      setMealToEdit(undefined)
    }
  }
  const onDeleteItem = (id: string) => {
    const item = items.find(item => item.id === id)
    if (item && childId) {
      onClearEditItem(item)
      deleteDoc(doc(firestoreDb, 'child', childId, 'history', item.id))
    } else {
      // TODO: do something here
    }
  }
  const onEditItem = (item: Item) => {
    console.log('ChildDataContext.onEditItem', item)
    const editableFields = ['left', 'right', 'bottle', 'bottleType', 'timestamp', 'name', 'amount']
    // Filter edited object to data fields only
    const newItem = Object.fromEntries(Object.entries(item).filter(([key]) => editableFields.includes(key)))
    if (childId) {
      setDoc(doc(firestoreDb, 'child', childId, 'history', item.id), newItem, {merge: true})
      onClearEditItem(item)
    } else {
      // TODO: do something here
    }
  }
  const addChild = (child: Partial<Child>) => {
    const uid = userContext.user?.uid!
    const childDocRef = doc(collection(firestoreDb, 'child'))
    const data = {
      id: childDocRef.id,
      name: child.name?.trim(),
      roles: {
        [uid]: 'editor'
      },
    }
    return setDoc(childDocRef, data)
      .then(result => console.log('Add Child Result:', result))
  }
  const deleteChild = (childId: string) => {
    const childDoc = doc(firestoreDb, 'child', childId)
    return deleteDoc(childDoc)
  }
  const shareChild = (child: Child, email: string) => {
    const shareChildFunction = httpsCallable(firebaseFunctions, 'shareChild')
    return shareChildFunction({
      childId: child.id,
      role: 'editor',
      email
    })
  }
  const setCurrentChildId = (childId?: string) => {
    console.log(`ChildDataContext.setCurrentChild setting current child: ${childId}`)
    setChildId(childId)
  }
  const incrementItemCount = (count?: number) => {
    setItemHistoryCount(prevState => prevState + (count || CHILD_LOAD_COUNT))
  }
  // const getChildHistory = (childId: string, size: number, callback: (items: Item[]) => void) => {
  //   const historyQuery = query(collection(firestoreDb, 'child', childId, 'history'),
  //     orderBy('timestamp', 'desc'), limit(size))
  //   return onSnapshot(historyQuery, snapshot => {
  //     const items = snapshot.docs.map(doc => {
  //       const data = doc.data()
  //       if (doc.metadata.hasPendingWrites) {
  //         console.log('Event from a local update')
  //       }
  //       return {
  //         ...data,
  //         id: doc.id,
  //         timestamp: data?.timestamp?.toDate() || new Date()
  //       } as Item
  //     })
  //     console.log('Items:', items)
  //     callback(items)
  //   })
  // }

  const contextValue: ChildDataContextType = {
    items,
    feedingToEdit,
    pumpingToEdit,
    mealToEdit,
    onAddItem,
    onDeleteItem,
    onSetFeedingToEdit: setFeedingToEdit,
    onSetPumpingToEdit: setPumpingToEdit,
    onSetMealToEdit: setMealToEdit,
    onEditItem,
    onClearEditItem,
    children,
    addChild,
    deleteChild,
    shareChild,
    childId,
    setCurrentChildId,
    incrementItemCount,
  }

  return <ChildDataContext.Provider value={contextValue}>
    {props.children}
  </ChildDataContext.Provider>
}

export enum ItemType {
  Feeding = 'Feeding',
  Pumping = 'Pumping',
  Meal = 'Meal',
}

export enum BottleType {
  BreastMilk = 'BM',
  Formula = 'F'
}

export interface Item {
  id: string
  timestamp: Date
  type: ItemType
}

export interface Feeding extends Item {
  left?: number
  right?: number
  bottle?: number,
  bottleType?: boolean
}

export interface Pumping extends Item {
  left?: number
  right?: number
}

export interface Meal extends Item {
  name?: string
  amount?: number
}

export type Child = {
  id: string
  name: string
}

type ChildDataContextType = {
  items: Item[]
  feedingToEdit?: Feeding
  pumpingToEdit?: Pumping,
  mealToEdit?: Meal
  onAddItem: (item: Partial<Item>) => void
  onDeleteItem: (id: string) => void
  onEditItem: (item: Item) => void
  onSetFeedingToEdit: (feeding: Feeding) => void
  onSetPumpingToEdit: (pumping: Pumping) => void
  onSetMealToEdit: (meal: Meal) => void
  onClearEditItem: (item?: Item) => void
  children: Child[]
  childId?: string
  addChild: (child: Partial<Child>) => Promise<any>
  deleteChild: (childId: string) => Promise<any>
  shareChild: (child: Child, email: string) => Promise<any>
  setCurrentChildId: (childId?: string) => void,
  incrementItemCount: (count?: number) => void
}

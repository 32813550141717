import {Grid, Slider, Stack, Switch, TextField, Typography} from '@mui/material'
import {Mark} from '@mui/base'
import {Control, Controller} from 'react-hook-form'
import React from 'react'

type FormSliderProps = {
  label: string
  control: Control
  name: string
  max: number
  step: number
  labelType?: 'minutes' | 'milliliters' | 'ounces' | undefined
  showSwitch: boolean
  switchName: string
  switchOnLabel: string
  switchOffLabel: string
}

const FormSlider = (props: FormSliderProps) => {
  let labelUnit: string
  switch (props.labelType) {
    case 'minutes':
      labelUnit = 'min'
      break
    case 'milliliters':
      labelUnit = 'mL'
      break
    case 'ounces':
      labelUnit = 'oz'
      break
  }

  const midValue = Math.floor(props.max / 2)
  const marks: Mark[] = [
    {value: 0, label: 0},
    {value: midValue, label: midValue},
    {value: props.max, label: props.max},
  ]
  let switchControl: JSX.Element | undefined
  if (props.showSwitch) {
    switchControl = <Controller name={props.switchName}
                                control={props.control}
                                defaultValue={false}
                                render={({field: {onChange, value}}) =>
                                  <Grid item xs={6} ml={1}>
                                    <Stack direction="row" spacing={1} alignItems="center">
                                      <Typography fontSize="small">{props.switchOffLabel}</Typography>
                                      <Switch size="small" checked={value} onChange={onChange}/>
                                      <Typography fontSize="small">{props.switchOnLabel}</Typography>
                                    </Stack>
                                  </Grid>
                                }/>
  }

  return <Controller name={props.name}
                     defaultValue={0}
                     control={props.control}
                     render={({field: {onChange, value}}) =>
                       <>
                         <Grid item xs={2}>
                           <Typography fontSize="small">{props.label}</Typography>
                         </Grid>
                         <Grid item xs={3}>
                           <TextField
                             value={value}
                             size="small"
                             onChange={onChange}
                             inputProps={{
                               step: props.step,
                               min: 0,
                               max: props.max,
                               type: 'number',
                             }}
                             InputProps={{
                               endAdornment: (<Typography variant="caption">{labelUnit}</Typography>)
                             }}
                           />
                         </Grid>
                         {switchControl}
                         <Grid item xs={12} px={1}>
                           <Slider value={value}
                                   onChange={onChange}
                                   max={props.max}
                                   marks={marks}
                                   step={props.step}
                           />
                         </Grid>
                       </>}
  />
}

FormSlider.defaultProps = {
  step: 1,
  showSwitch: false,
  switchName: '',
  switchOnLabel: '',
  switchOffLabel: ''
}

export default FormSlider

import React, {useState} from 'react'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import './App.css'
import {Box, Container, createTheme, Theme, ThemeProvider, Typography} from '@mui/material'
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import LoginPage from './pages/LoginPage'
import UserContextProvider from './contexts/UserContext'
import TrackerPage from './pages/TrackerPage'
import RequireAuth from './components/RequireAuth'
import ChildPage from './pages/ChildPage'
import ChildDataContextProvider from './contexts/ChildDataContext'
import {LocalizationProvider} from '@mui/x-date-pickers'
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'
import AppDrawer from './components/AppDrawer'
import {BREAKPOINT_APP_DRAWER, WIDTH_PX_APP_DRAWER} from './util/Constants'
import {grey} from '@mui/material/colors'
import {getTheme} from './util/Storage'

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    // palette values for dark mode
    background: {
      default: grey[900],
      paper: grey[900]
    }
  }
})
const lightTheme = createTheme({
  palette: {
    mode: 'light'
  }
})

function App() {
  const defaultTheme = getTheme() === 'dark' ? darkTheme : lightTheme
  const [theme, setTheme] = useState<Theme>(defaultTheme)
  const toggleTheme = (theme: 'light' | 'dark') => {
    console.log('Updating theme', theme)
    if (theme === 'light') {
      setTheme(lightTheme)
    } else {
      setTheme(darkTheme)
    }
  }
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="md" sx={{pt: 9, ml: {[BREAKPOINT_APP_DRAWER]: `${WIDTH_PX_APP_DRAWER}px`}}}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <UserContextProvider>
            <ChildDataContextProvider>
              <BrowserRouter>
                <AppDrawer toggleTheme={toggleTheme}/>
                <Box component="main"
                     sx={{flexGrow: 1, width: {sm: `calc(100% - ${WIDTH_PX_APP_DRAWER}px)`}}}>
                  <Routes>
                    <Route path="/" element={
                      <RequireAuth>
                        <ChildPage/>
                      </RequireAuth>
                    }/>
                    <Route path="/login" element={<LoginPage/>}/>
                    <Route path="/about" element={<Typography variant="h1" fontSize="2em">Coming Soon</Typography>}/>
                    <Route path="/child/:childId" element={
                      <RequireAuth>
                        <TrackerPage/>
                      </RequireAuth>
                    }/>
                  </Routes>
                </Box>
              </BrowserRouter>
            </ChildDataContextProvider>
          </UserContextProvider>
        </LocalizationProvider>
      </Container>
    </ThemeProvider>
  )
}

export default App
